import React, { useEffect } from "react"
import { node, shape } from "prop-types"
import { Provider as AlertProvider } from "react-alert"
import Alert, { options } from "../Alert"
import SEO, { SEOProps } from "../Seo"
import GlobalStyle from "./style"
import useGlobal from "../../store"

const Layout = ({ children, seo }) => {
	const [globalState, globalActions] = useGlobal()

	useEffect(() => {
		function handleResize() {
			globalActions.viewportDimensionAction()
		}
		handleResize()
		window.addEventListener("resize", handleResize)
		return () => window.removeEventListener("resize", handleResize)
	}, []) // Empty array ensures that effect is only run on mount and unmount
	return (
		<AlertProvider template={Alert} {...options}>
			<div id="outer-container">
				<div id="inner-container">
					<GlobalStyle modalOpen={globalState.modalOpen} />
					<SEO {...seo} />
					<div id="page-wrap">{children}</div>
				</div>
			</div>
		</AlertProvider>
	)
}
export default Layout

export const layoutProps = {
	seo: shape(SEOProps).isRequired,
}

Layout.propTypes = {
	children: node.isRequired,
	...layoutProps,
}
