import React from "react"
import { shape } from "prop-types"
import Layout, { layoutProps } from "../components/Layout/clean"
import Row from "../components/Layout/Content/Row"
import OnlyForm from "../components/Form"
import skin from "../components/_globalSettings/skin"
import Button from "../components/Link/Button"

const FormPage = ({ pageContext }) => {
	return (
		<Layout {...pageContext}>
			<Row>
				<div
					style={{ maxWidth: "600px", margin: "auto", position: "relative" }}
				>
					<div style={{ textAlign: "right" }}>
						<Button
							href={typeof window === "object" ? window.previousPath : "/"}
							skin={skin.darkGhost}
							className="close"
						>
							&times;
						</Button>
					</div>
					<h1>{pageContext.form.headline}</h1>
					<OnlyForm data={pageContext.form.form} id="common" />
				</div>
			</Row>
		</Layout>
	)
}

FormPage.propTypes = {
	pageContext: shape({
		...layoutProps,
	}).isRequired,
}

export default FormPage
